import * as queryString from 'query-string';
import React, { Component, createRef, RefObject } from 'react';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { twMerge } from 'tailwind-merge';

import { OriginArea } from '@videoblocks/events-ts/lib/storyblocks/search/OriginAreaEnum';
import { Telemetry } from '@videoblocks/kafka-rest-client';
import { LegacyVideo as Video } from '@videoblocks/storywind';

import { setPendingPauseAudio } from '../../../../Audio/actions/AudioActions';
import displayEventMediator from '../../../../Events/DisplayEventMediator';
import events from '../../../../Events/Events';
import StockItemDisplayEventFactory from '../../../../Events/StockItemDisplayEventFactory';
import StockItemHoverEventFactory from '../../../../Events/StockItemHoverEventFactory';
import { Folder } from '../../../../Member/Folders/types';
import { selectMemberBins } from '../../../../Member/MemberBins/selectors/foldersSelectors';
import { selectPopUpInstanceSpecificInfo } from '../../../../PopUps/selectors/PopUpsSelectors';
import { STORYBOARD_REQUEST_ID_COOKIE } from '../../../../Storyboards/constants';
import {
  selectHasVideoSubscription,
  selectIsLoggedIn,
  selectIsMobile,
  selectPrimarySubscription,
} from '../../../../auth/AuthSelectors';
import { Subscription } from '../../../../auth/AuthTypes';
import { contentTypes } from '../../../../common/Constants';
import ElementDisplayedListener from '../../../../common/ElementDisplayedListener';
import Logger from '../../../../common/Logger';
import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import Duration from '../../../../common/components/Duration';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';
import { StockDownloadContext } from '../../../../common/context/StockDownloadContext';
import {
  StockItem,
  StockItemContext,
  StockItemFormat,
} from '../../../../common/types/StockItemTypes';
import {
  convertStockItemContextToOriginArea,
  getPreviewUrl,
  isMouseOverElement,
  startRecommendedMusicDrawerTimer,
  stopRecommendedMusicDrawerTimer,
} from '../../../../common/utils';
import { ContentClass } from '../../../Shared/enums';
import { DrawerType } from '../../SearchTypes';
import {
  collapseMoreLikeThisDrawer,
  expandMoreLikeThisDrawer,
  fetchCollapsedSetResults,
  setSelectedStockItemOptions,
  updateSearchPage,
} from '../../actions/SearchActions';
import { VideoSelectedSearchFilterOptions } from '../../containers/MenuContainerInterfaces';
import {
  selectDrawerIsOpen,
  selectDrawerSelectedItemId,
  selectDrawerType,
  selectSearchFeatures,
  selectSearchFilterOptions,
  selectSelectedStockItem,
} from '../../selectors/searchSelectors';
import VisitorDownloadModal from '../modal/VisitorDownloadModal';
import StockItemCardButtons from './StockItemCardButtons';
import StockItemIcons from './StockItemIcons';
import VideoStockItemFormatSelector from './VideoStockItemFormatSelector';
import {
  DeleteButton,
  DownloadButton,
  FavoriteButton,
  MakerButton,
  RecommendedMusicButton,
  SimilarButton,
} from './components/Buttons';

import './VideoStockItemCard.less';

export type VideoStockItemCardProps = {
  binUniqueId: string;
  classNames: string[];
  containerResponsiveClassNames: string[];
  containPoppers: boolean;
  context: StockItemContext;
  draggableCard: boolean;
  handleLoadingMoreCollapsedStockItems: () => void;
  hideOnThreeColumnLayout: boolean;
  isCollapsedStockItemLoadMoreCard: boolean;
  isSelected: boolean;
  page: number;
  searchLogParameters: object;
  shouldInvertControlsVisibility: boolean;
  shouldShowAddToFavorites: boolean;
  shouldShowDeleteButton: boolean;
  shouldShowDownloadButton: boolean;
  shouldStockItemHaveNewFlag: boolean;
  shouldShowMakerButton: boolean;
  stockItem: StockItem;
  stockItemCardContext?: { title: string; position: number };
  stockItemFormats: StockItemFormat[];
  style: object;
  thumbnailClassNames: string;
  isLoggedIn: boolean;
  videoSubscription: Subscription;
  primarySubscription: Subscription;
  isMobile: boolean;
  formatSelectorVisible: boolean;
  dispatch: ThunkDispatch<any, any, any>;
  downloadButtonTelemetryAction: () => void;
  makerButtonTelemetryAction: () => void;
  favoriteButtonTelemetryAction: () => void;
  openDetailsInNewWindow: boolean;
  openFoldersInNewWindow: boolean;
  redirectToSignUpLink: boolean;
  selectedFormatName?: string;
  selectedFormatResolution?: string;
  downloadPermissions?: Record<string, boolean>;
  selectedSearchFilterOptions: VideoSelectedSearchFilterOptions;
  drawerIsOpen: boolean;
  drawerSelectedItemId: number;
  drawerType: DrawerType;
  bins: Folder[];
  updateSearchPage: typeof updateSearchPage;
  setSelectedStockItemOptions: typeof setSelectedStockItemOptions;
  collapseMoreLikeThisDrawer: typeof collapseMoreLikeThisDrawer;
  expandMoreLikeThisDrawer: typeof expandMoreLikeThisDrawer;
  fetchCollapsedSetResults: typeof fetchCollapsedSetResults;
  setPendingPauseAudio: typeof setPendingPauseAudio;
  isInThumbnailSimplifyExperiment: boolean;
  isInVisitorDownloadModalExperiment: boolean;
  shouldUseRemainingUi: boolean;
};

type State = {
  accumulatedPlayMs: number;
  isDragging: boolean;
  isHovering: boolean;
  isPlaying: boolean;
  playPromiseProcessing: boolean;
  showDeleteTooltip: boolean;
  showDownloadTooltip: boolean;
  showFavoriteTooltip: boolean;
  showMoreLikeThisTooltip: boolean;
  showRecommendedMusicTooltip: boolean;
  hoverStartTime: number;
  playStartTime: number;
  renderDownloadModal: boolean;
  cardInViewport: boolean;
};

class VideoStockItemCard extends Component<VideoStockItemCardProps, State> {
  static defaultProps = {
    classNames: [],
    containPoppers: false,
    containerResponsiveClassNames: [
      'relative',
      'float-left',
      'md:w-1/3',
      'lg:w-1/4',
      'w-5/6',
    ],
    draggableCard: false,
    isCollapsedStockItemLoadMoreCard: false,
    shouldShowAddToFavorites: true,
    shouldShowDownloadButton: true,
    shouldShowDeleteButton: false,
    shouldShowMakerButton: true,
    shouldInvertControlsVisibility: false,
    style: {},
    thumbnailClassNames: '',
    isMobile: false,
    formatSelectorVisible: false,
    downloadButtonTelemetryAction: null,
    makerButtonTelemetryAction: null,
    openDetailsInNewWindow: false,
    openFoldersInNewWindow: false,
  };

  state = {
    accumulatedPlayMs: 0,
    isDragging: false,
    isHovering: false,
    isPlaying: false,
    playPromiseProcessing: false,
    showDeleteTooltip: false,
    showDownloadTooltip: false,
    showFavoriteTooltip: false,
    showMoreLikeThisTooltip: false,
    showRecommendedMusicTooltip: false,
    hoverStartTime: null,
    playStartTime: null,
    renderDownloadModal: false,
    cardInViewport: false,
  };

  containerRef: RefObject<HTMLDivElement>;
  videoRef: RefObject<{ videoRef: RefObject<HTMLVideoElement> }>;

  constructor(props) {
    super(props);

    this.containerRef = createRef();
    this.videoRef = createRef();
  }

  componentDidMount() {
    // track drags to tell if a touch is a deliberate or the end of a drag
    this.containerRef.current.addEventListener('touchmove', () => {
      if (!this.state.isDragging) {
        this.setState({
          isDragging: true,
        });
      }
    });

    this.containerRef.current.addEventListener('touchstart', () => {
      if (this.state.isDragging) {
        this.setState({
          isDragging: false,
        });
      }
    });

    // render content when containerRef starts to enter the viewport
    ElementDisplayedListener(
      this.containerRef.current,
      () => {
        this.setState({ cardInViewport: true });
      },
      0.1
    );

    ElementDisplayedListener(this.containerRef.current, () => {
      const context = this.props.stockItemCardContext;

      if (context?.position) {
        displayEventMediator.push(
          StockItemDisplayEventFactory.displayedStockItem(
            this.props.stockItem,
            context.position
          )
        );
      }
    });

    this.shouldShowMoreLikeThisTooltipByDefault();

    // ensure recommended music drawer timer is stopped when navigating away
    window.addEventListener('beforeunload', () => {
      // @ts-ignore
      window.recommendedMusicDrawerTimer?.();
    });
  }

  componentWillUnmount() {
    // ensure we pause music and send audio preview and drawer events if new search is performed / user moves to another page
    this.pauseAudioAndStopRecommendedMusicDrawerTimer();
  }

  render() {
    const { isCollapsedStockItemLoadMoreCard, stockItem, style } = this.props;
    const { renderDownloadModal, cardInViewport } = this.state;

    return (
      <div
        role="button"
        tabIndex={0}
        className={this.getContainerClassNames()}
        data-stock-id={stockItem.id}
        ref={this.containerRef}
        onMouseEnter={this.handleMouseEnter}
        onFocus={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onBlur={this.handleMouseLeave}
        onClick={this.handleStockItemClick}
        onKeyDown={this.handleStockItemClick}
        style={style}
        itemType="schema.org/VideoObject"
      >
        {cardInViewport && isCollapsedStockItemLoadMoreCard
          ? this.renderCollapsedStockItemLoadMoreCard()
          : this.renderNormalStockItemCard()}
        {renderDownloadModal ? (
          <VisitorDownloadModal
            isOpen={renderDownloadModal}
            smallImageUrl={stockItem.thumbnailUrl}
            mediumImageUrl={stockItem.mediumImageUrl}
            onClose={this.handleModalClose}
            stockItemTitle={stockItem.title}
          />
        ) : null}
      </div>
    );
  }

  handleMouseEnter = () => {
    this.handleIsHovering();
    if (!this.props.isMobile) {
      this.play();
    }
  };

  handleIsHovering = () => {
    if (!this.props.formatSelectorVisible) {
      this.setState({
        isHovering: true,
        hoverStartTime: Date.now(),
      });

      if (this.props.page) {
        this.props.updateSearchPage(this.props.page, this.props.stockItem.id);
      }
    }
  };

  handleStockItemClick = (e) => {
    e.stopPropagation();
    if (e.target.nodeName === 'VIDEO') {
      const { stockItem, stockItemCardContext } = this.props;
      Logger.produceDetailsClickEvent(
        stockItem,
        stockItemCardContext && stockItemCardContext.position
      );
      this.logHoverDone();
    }
  };

  handleMouseLeave = (e) => {
    /**
     * Click on the favorite button will sometimes trigger
     * a mouseLeave event based on the Popper's positioning,
     * preventing the Popper from appearing. This check blocks
     * that from happening.
     */
    if (
      e?.relatedTarget?.className?.includes?.('tooltip') ||
      e?.target?.className?.includes?.('action-icon')
    ) {
      return;
    }
    this.handleIsNotHovering();
    this.pause();
  };

  handleIsNotHovering = () => {
    this.setState({
      isHovering: false,
      playStartTime: null,
      accumulatedPlayMs: this.state.accumulatedPlayMs + this.playDurationMs(),
    });

    this.logHoverDone();
  };

  handleClickAction = (e) => {
    if (!isMouseOverElement(e?.nativeEvent || e, this.containerRef.current)) {
      this.handleMouseLeave(e);
    }
  };

  renderCollapsedStockItemLoadMoreCard() {
    const {
      handleLoadingMoreCollapsedStockItems,
      shouldStockItemHaveNewFlag,
      stockItem,
      thumbnailClassNames,
    } = this.props;

    return (
      <div
        onClick={handleLoadingMoreCollapsedStockItems}
        onKeyDown={handleLoadingMoreCollapsedStockItems}
        role="button"
        tabIndex={0}
      >
        <div
          className={`box thumbnail faded load-more ${thumbnailClassNames} z-0`}
        >
          <StockItemIcons showNewFlag={shouldStockItemHaveNewFlag} />

          <img
            className="action-icon thumbnail-img"
            src={stockItem.thumbnailUrl}
            alt={stockItem.title}
            loading="eager"
          />

          <div className="controls">{this.renderStockItemTitle()}</div>
        </div>

        <h4 className="faded-thumbnail-text">Load More</h4>
      </div>
    );
  }

  renderNormalStockItemCard() {
    const {
      draggableCard,
      formatSelectorVisible,
      shouldStockItemHaveNewFlag,
      thumbnailClassNames,
    } = this.props;
    const { isHovering, isPlaying } = this.state;

    return (
      <>
        <div className="box thumbnail z-0">
          <div
            className={`thumbnail-img-wrapper overflow-hidden ${thumbnailClassNames}`}
          >
            {formatSelectorVisible && this.renderStockItemFormatSelector()}

            <StockItemIcons showNewFlag={shouldStockItemHaveNewFlag} />
            {draggableCard
              ? this.renderVideo()
              : this.renderVideoWithDetailsLink()}
            <LoadingIndicator show={isHovering && !isPlaying} />
          </div>

          {this.renderButtons()}

          {this.shouldShowSimilarContent()}
        </div>

        {this.renderActionButtons()}
        {this.renderDrawerButtons()}
      </>
    );
  }

  play() {
    if (this.videoRef && this.videoRef.current) {
      const playPromise = this.videoRef.current.videoRef.current.play();
      this.setState({ playPromiseProcessing: true });

      if (playPromise) {
        playPromise
          .then(() => {
            this.setState({ playPromiseProcessing: false });
            if (!this.state.isHovering) {
              this.pause();
            }
            // Playback started!
          })
          .catch((e) => {
            // Playback failed.
            this.setState({ playPromiseProcessing: false });

            // If it is not a DOMException AbortError then retry the playback
            // This error occurs when a video is paused before playback started
            if (!(e instanceof DOMException && e.name === 'AbortError')) {
              if (this.state.isHovering) {
                this.play();
              }

              // Log the exception so we can learn what is being thrown
              // console.error('Playback exception: ', {
              //   name: e.name,
              //   errorType: e,
              // });
            }
          });
      }
    }
  }

  pause() {
    if (
      this.videoRef &&
      this.videoRef.current &&
      !this.state.playPromiseProcessing
    ) {
      this.videoRef.current.videoRef.current.pause();
    }
  }

  renderStockItemFormatSelector() {
    return (
      <VideoStockItemFormatSelector
        isVisible={this.props.formatSelectorVisible}
        stockItemFormats={this.props.stockItemFormats}
        selectedFormatName={this.props.selectedFormatName}
        selectedFormatResolution={this.props.selectedFormatResolution}
        selectedFormatChanged={(selectedFormatName, selectedFormatResolution) =>
          this.handleSelectedFormatChanged(
            selectedFormatName,
            selectedFormatResolution
          )
        }
        downloadPermissions={this.props.downloadPermissions}
      />
    );
  }

  handleSelectedFormatChanged = (
    selectedFormatName,
    selectedFormatResolution
  ) => {
    this.props.setSelectedStockItemOptions({
      id: this.props.stockItem.id,
      selectedFormatName,
      selectedFormatResolution,
    });
  };

  renderActionButtons() {
    const { isHovering } = this.state;
    const {
      shouldShowAddToFavorites,
      shouldShowDownloadButton,
      downloadButtonTelemetryAction,
      stockItem,
      makerButtonTelemetryAction,
      binUniqueId,
      shouldShowDeleteButton,
      containPoppers,
      openFoldersInNewWindow,
      stockItemFormats,
      favoriteButtonTelemetryAction,
      context,
      isInThumbnailSimplifyExperiment,
    } = this.props;

    return (
      <div
        className={twMerge(
          'action-buttons right-2 flex justify-end space-x-2',
          `${!isHovering ? 'lg:hidden' : ''}`
        )}
      >
        <DownloadButton
          shouldShowDownloadButton={shouldShowDownloadButton}
          downloadButtonTelemetryAction={downloadButtonTelemetryAction}
          onClick={this.getPrimaryClickAction()}
        />
        {shouldShowAddToFavorites && !isInThumbnailSimplifyExperiment && (
          <FavoriteButton
            containPoppers={containPoppers}
            stockItem={stockItem}
            stockItemFormats={stockItemFormats}
            favoriteButtonTelemetryAction={favoriteButtonTelemetryAction}
            openFoldersInNewWindow={openFoldersInNewWindow}
            onClick={this.handleClickAction}
            context={context}
          />
        )}
        <DeleteButton
          binUniqueId={binUniqueId}
          shouldShowDeleteButton={shouldShowDeleteButton}
          stockItem={stockItem}
        />
        {this.shouldShowMakerButton() && (
          <MakerButton
            stockItem={stockItem}
            makerButtonTelemetryAction={makerButtonTelemetryAction}
          />
        )}
      </div>
    );
  }

  renderDrawerButtons() {
    const { stockItem, isInThumbnailSimplifyExperiment } = this.props;
    const { isHovering } = this.state;

    let thumbnailExperimentClasses: string;
    if (isInThumbnailSimplifyExperiment) {
      thumbnailExperimentClasses = twMerge(
        'transition-none',
        isHovering ? 'opacity-100' : 'opacity-0'
      );
    }

    return (
      <div
        className={twMerge(
          'drawer-buttons right-2 flex space-x-2',
          thumbnailExperimentClasses
        )}
      >
        {this.shouldShowSimilarContent() && (
          <SimilarButton
            stockItem={stockItem}
            onClick={this.similarContentAction}
          />
        )}
        {this.shouldShowRecommendedMusic() && (
          <RecommendedMusicButton
            stockItem={stockItem}
            onClick={this.recommendedMusicAction}
          />
        )}
      </div>
    );
  }

  renderButtons() {
    const {
      formatSelectorVisible,
      shouldShowDownloadButton,
      shouldInvertControlsVisibility,
    } = this.props;
    const { isHovering } = this.state;

    const hoverStyle = shouldInvertControlsVisibility ? '' : 'bg-transparent';
    const nonHoverStyle = shouldInvertControlsVisibility
      ? 'bg-transparent'
      : '';

    return (
      <div
        className={twMerge(
          'controls flex video-title-and-gradient',
          isHovering ? hoverStyle : nonHoverStyle
        )}
      >
        {this.renderStockItemTitle()}

        {formatSelectorVisible && shouldShowDownloadButton && (
          <StockItemCardButtons
            onPrimaryActionClicked={this.getPrimaryClickAction()}
            onSecondaryActionClicked={this.hideDownloadButtons}
            showCancelLabel={this.props.formatSelectorVisible}
            redirectToSignUpLink={this.props.redirectToSignUpLink}
            detailsUrl={this.props.stockItem.detailsUrl}
          />
        )}
      </div>
    );
  }

  hideDownloadButtons = (event) => {
    event.preventDefault();

    this.props.setSelectedStockItemOptions({
      formatSelectorVisible: false,
    });

    this.setState({
      isHovering: true,
    });
  };

  renderStockItemTitle() {
    const { shouldInvertControlsVisibility, isInThumbnailSimplifyExperiment } =
      this.props;
    const { duration, title } = this.props.stockItem;
    const { isHovering } = this.state;

    const hoverStyle = shouldInvertControlsVisibility
      ? 'opacity-100'
      : 'opacity-0';
    const nonHoverStyle = shouldInvertControlsVisibility
      ? 'opacity-0'
      : 'opacity-100';

    let thumbnailExperimentClasses: string;
    if (isInThumbnailSimplifyExperiment) {
      thumbnailExperimentClasses = twMerge(
        'transition-none',
        isHovering ? 'opacity-100' : 'opacity-0'
      );
    }

    let widthClass: string;
    if (this.shouldShowSimilarContent() && this.shouldShowRecommendedMusic()) {
      widthClass = 'w-3/5';
    } else if (
      this.shouldShowSimilarContent() ||
      this.shouldShowRecommendedMusic()
    ) {
      widthClass = 'w-5/6';
    } else {
      widthClass = 'w-full';
    }

    return (
      <div
        className={twMerge(
          'item-title flex flex-wrap',
          widthClass,
          isHovering ? hoverStyle : nonHoverStyle,
          thumbnailExperimentClasses
        )}
      >
        {duration && <Duration seconds={duration} />}
        <h3 className="truncate text-sm font-semibold video-card-redesign-title w-full mb-0 mr-4">
          {title}
        </h3>
      </div>
    );
  }

  shouldShowMakerButton() {
    const {
      stockItem,
      shouldShowMakerButton,
      isInThumbnailSimplifyExperiment,
    } = this.props;
    return (
      shouldShowMakerButton &&
      !isInThumbnailSimplifyExperiment &&
      (stockItem.contentType === 'footage' ||
        stockItem.contentType === 'motionbackground') &&
      !stockItem.is360 &&
      !this.props.isMobile
    );
  }

  shouldShowSimilarContent() {
    // NOTE -- The array of related Items ALWAYS INCLUDES stockItem itself.
    //         So if there is only 1 Item in the collapseSetId array,
    //         then we don't have any related Items.
    //
    // If there are only 2 Items in the collapseSetId array,
    //      then we only have one other related Item,
    //      and we should NOT SHOW any UI related to Collapsed Content.
    return (
      this.props.stockItem.numCollapses > 2 &&
      this.props.context === StockItemContext.SEARCH
    );
  }

  shouldShowRecommendedMusic() {
    const { context, stockItem, isInThumbnailSimplifyExperiment } = this.props;
    return (
      stockItem.contentType === contentTypes.FOOTAGE &&
      stockItem.hasRecommendedMusic &&
      context === StockItemContext.SEARCH &&
      !isInThumbnailSimplifyExperiment
    );
  }

  handleVideoStartedPlaying = () => {
    this.setState({
      isPlaying: true,
      playStartTime: this.state.playStartTime || Date.now(), // Because videos loop, don't reset the playStartTime
    });
  };

  playDurationMs() {
    return this.state.playStartTime ? Date.now() - this.state.playStartTime : 0;
  }

  logHoverDone() {
    if (this.state.hoverStartTime) {
      const hoverDuration = Date.now() - this.state.hoverStartTime;
      const playEnd = this.videoRef.current.videoRef.current.currentTime;

      // Needed to avoid a race condition because we both set it and use it here
      const newAccumulatedPlayMs =
        this.state.accumulatedPlayMs + this.playDurationMs();

      const timesLooped = Math.floor(
        newAccumulatedPlayMs / 1000 / this.props.stockItem.duration
      );

      const originArea = convertStockItemContextToOriginArea(
        this.props.context
      );

      let storyboardRequestId: string;
      let storyboardSceneRequestId: string;

      if (originArea === OriginArea.STORYBOARD) {
        storyboardRequestId = cookie.load(STORYBOARD_REQUEST_ID_COOKIE);
        storyboardSceneRequestId = this.context.sceneRequestId;
      }

      const hoverEvent = StockItemHoverEventFactory.create(
        this.props.stockItem,
        this.state.hoverStartTime,
        this.state.playStartTime,
        playEnd,
        timesLooped,
        newAccumulatedPlayMs,
        Date.now(),
        originArea,
        storyboardRequestId,
        storyboardSceneRequestId
      );

      if (hoverDuration > 1000) {
        events.produce(hoverEvent, hoverEvent.user.visitorCookieId);
      }
    }
  }

  async primaryActionDownloadItem(selectedFormat) {
    const { primarySubscription, videoSubscription, context } = this.props;

    const params = this.context; // StockDownloadContext

    const originArea = convertStockItemContextToOriginArea(context);
    if (originArea) {
      params['origin_area'] = originArea;
    }

    Telemetry.increment('download.clicked.stockItemCard');
    const dataBridge = await SiteConstants.buildInstance().getDataBridge();
    dataBridge.downloadItem(
      queryString.stringifyUrl(
        {
          url: selectedFormat.downloadUrl,
          query: params,
        },
        {
          skipNull: true,
        }
      ),
      queryString.stringifyUrl(
        {
          url: selectedFormat.downloadAjaxUrl,
          query: params,
        },
        {
          skipNull: true,
        }
      ),
      this.props.searchLogParameters,
      this.props.dispatch,
      // NOTE: used to provide data for Intercom in Starter Plan A/B Test, remove after test if possible
      primarySubscription || videoSubscription
    );
  }

  handleOnTouchEnd = () => {
    const { isDragging } = this.state;

    if (!isDragging) {
      const { detailsUrl } = this.props.stockItem;
      window.location.href = detailsUrl;
    }
  };

  handleModalClose = () => {
    this.setState({ renderDownloadModal: false });
  };

  renderVideoWithDetailsLink() {
    const { stockItem } = this.props;

    const src = this.context.sceneRequestId
      ? `${stockItem.detailsUrl}?sceneRequestId=${this.context.sceneRequestId}`
      : stockItem.detailsUrl;

    return (
      <a
        className="image-link"
        href={src}
        onTouchEnd={this.handleOnTouchEnd}
        aria-label={`Go to video details for ${stockItem.title}`}
        target={this.props.openDetailsInNewWindow ? '_blank' : '_self'}
      >
        <div className="overflow-hidden rounded-3xl">{this.renderVideo()}</div>
      </a>
    );
  }

  renderVideo() {
    const { stockItem } = this.props;

    return (
      <Video
        ref={this.videoRef}
        videoUrl={stockItem.previewUrl}
        poster={stockItem.thumbnailUrl}
        onStartedPlaying={this.handleVideoStartedPlaying}
        preload={false}
        className="object-fill h-full"
      />
    );
  }

  getPrimaryClickAction() {
    const {
      stockItem,
      videoSubscription,
      isInVisitorDownloadModalExperiment,
      isLoggedIn,
    } = this.props;

    if (this.isASubscriptionlessUser()) {
      return () => {
        Telemetry.increment('preview.downloaded.stockItemCard');
        window.location.href = getPreviewUrl(
          stockItem.id,
          stockItem.contentClass
        );
      };
    }

    if (!isLoggedIn && isInVisitorDownloadModalExperiment) {
      return (event) => {
        event.preventDefault();
        this.logPrimaryClickAction();
        this.setState({ renderDownloadModal: true });
      };
    }

    if (stockItem && !videoSubscription) {
      const visitorDownloadAttemptLink = stockItem.detailsUrl;

      return (event) => {
        event.preventDefault();
        this.logPrimaryClickAction();
        window.location.href = visitorDownloadAttemptLink;
      };
    }
    return this.getDefaultPrimaryClickAction();
  }

  getDefaultPrimaryClickAction() {
    return (event) => {
      this.logPrimaryClickAction();

      if (!this.overrideClickActionsForRedirect()) {
        event.preventDefault();

        if (
          !this.props.formatSelectorVisible &&
          this.props.stockItemFormats.length >= 2
        ) {
          this.primaryActionUpdateSelectedStockItemOption();
        } else {
          const selectedFormat = this.getSelectedFormat();
          this.primaryActionDownloadItem(selectedFormat);
        }
      }
    };
  }

  getSelectedFormat() {
    const { stockItemFormats, selectedFormatName, selectedFormatResolution } =
      this.props;

    return stockItemFormats.length >= 2 && selectedFormatName
      ? stockItemFormats.find((stockItemFormat) => {
          return (
            stockItemFormat.formatName === selectedFormatName &&
            stockItemFormat.resolution === selectedFormatResolution
          );
        })
      : stockItemFormats[0];
  }

  overrideClickActionsForRedirect() {
    return this.props.redirectToSignUpLink;
  }

  primaryActionUpdateSelectedStockItemOption() {
    this.setState({
      isHovering: false, // Disable hover state when format selector is visible
      isPlaying: false,
    });

    this.props.setSelectedStockItemOptions({
      id: this.props.stockItem.id,
      formatSelectorVisible: true,
      selectedFormatName: this.props.stockItemFormats[0].formatName,
      selectedFormatResolution: this.props.stockItemFormats[0].resolution,
    });
  }

  logPrimaryClickAction() {
    const { stockItem, stockItemCardContext } = this.props;
    Logger.produceDownloadClickEvent(
      stockItem,
      stockItemCardContext && stockItemCardContext.position
    );
    this.logHoverDone();
  }

  similarContentAction = () => {
    const {
      selectedSearchFilterOptions,
      drawerIsOpen,
      drawerSelectedItemId,
      drawerType,
      stockItem,
    } = this.props;

    if (
      drawerIsOpen &&
      drawerType === DrawerType.MORE_LIKE_THIS &&
      drawerSelectedItemId === stockItem.id
    ) {
      this.props.collapseMoreLikeThisDrawer();
    } else {
      // ensure we pause audio and stop the suggestions drawer timer if a suggestions drawer is open
      this.pauseAudioAndStopRecommendedMusicDrawerTimer();

      const { collapsedSetId, id } = stockItem;
      const updateFields = {
        collapsedSetId,
        id,
        excludeStockItemIds: stockItem.id,
        page: 1,
      };
      const newSearchOptions = selectedSearchFilterOptions.update(updateFields);
      this.props.fetchCollapsedSetResults(newSearchOptions);
      this.props.expandMoreLikeThisDrawer({
        initialItemId: id,
        drawerType: DrawerType.MORE_LIKE_THIS,
      });
    }
  };

  recommendedMusicAction = () => {
    const {
      selectedSearchFilterOptions,
      drawerIsOpen,
      drawerSelectedItemId,
      drawerType,
      stockItem,
    } = this.props;

    // pause audio and stop the timer if the drawer is being closed or if a different suggestions drawer is about to open
    drawerType === DrawerType.RECOMMENDED_MUSIC &&
      this.pauseAudioAndStopRecommendedMusicDrawerTimer();

    if (
      drawerIsOpen &&
      drawerType === DrawerType.RECOMMENDED_MUSIC &&
      drawerSelectedItemId === stockItem.id
    ) {
      this.props.collapseMoreLikeThisDrawer();
    } else {
      Telemetry.increment('search.recommended_music.opened');
      startRecommendedMusicDrawerTimer();

      const { contentType, id } = stockItem;

      const updateFields = {
        contentSuggestions: {
          contentClass: ContentClass.Audio,
          contentType: contentTypes.MUSIC,
          // this an array as we may support multiple sources in the future
          sources: [
            {
              contentType,
              id,
            },
          ],
        },
      };
      // @ts-ignore
      const newSearchOptions = selectedSearchFilterOptions.update(updateFields);
      this.props.fetchCollapsedSetResults(newSearchOptions);
      this.props.expandMoreLikeThisDrawer({
        initialItemId: id,
        drawerType: DrawerType.RECOMMENDED_MUSIC,
      });
    }
  };

  pauseAudioAndStopRecommendedMusicDrawerTimer() {
    this.props.setPendingPauseAudio();
    stopRecommendedMusicDrawerTimer();
  }

  getContainerClassNames() {
    const context = this.props.context;
    return twMerge(
      this.props.containerResponsiveClassNames,
      this.props.classNames,
      'stock-item stock-item-v2',
      this.props.isSelected && 'is-selected',
      this.props.formatSelectorVisible && 'download-format-selector-visible',
      this.props.hideOnThreeColumnLayout && 'hidden-sm hidden-md',
      this.shouldShowSimilarContent() && 'collapsed-card',
      this.props.shouldUseRemainingUi &&
        context !== StockItemContext.DRAWER &&
        'p-0 w-auto md:w-auto lg:w-auto 3xl:w-auto 4xl:w-auto m-0'
    );
  }

  isASubscriptionlessUser() {
    return this.props.isLoggedIn && !this.props.videoSubscription;
  }

  shouldShowMoreLikeThisTooltipByDefault() {
    const hasSeenMoreLikeThisTooltip = cookie.load('morelikethis');
    const domain = window && window.location && `.${window.location.hostname}`;

    if (!hasSeenMoreLikeThisTooltip) {
      cookie.save('morelikethis', true, { path: '/', domain });
      this.setState({ showMoreLikeThisTooltip: true });
    }

    this.setState({ showMoreLikeThisTooltip: false });
  }
}

function mapStateToPropsVideo(state, props) {
  const selectedStockItem = selectSelectedStockItem(state);
  const features = selectSearchFeatures(state);
  const { plansAndPricingUpgradeInfo } = selectPopUpInstanceSpecificInfo(state);
  const isStockItemSelected = selectedStockItem.id === props.stockItem.id;

  return {
    isMobile: selectIsMobile(state),
    formatSelectorVisible: isStockItemSelected
      ? selectedStockItem.formatSelectorVisible
      : false,
    selectedFormatName: isStockItemSelected
      ? selectedStockItem.selectedFormatName
      : null,
    selectedFormatResolution: isStockItemSelected
      ? selectedStockItem.selectedFormatResolution
      : null,
    plansAndPricingUpgradeInfo,
    drawerIsOpen: selectDrawerIsOpen(state),
    drawerSelectedItemId: selectDrawerSelectedItemId(state),
    drawerType: selectDrawerType(state),
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    bins: selectMemberBins(state),
    downloadPermissions: features.downloadPermissions,
    user: state.auth,
    isLoggedIn: selectIsLoggedIn(state),
    videoSubscription: selectHasVideoSubscription(state),
    primarySubscription: selectPrimarySubscription(state),
    isInThumbnailSimplifyExperiment: features.isInThumbnailSimplifyExperiment,
    isInVisitorDownloadModalExperiment:
      features.isInVisitorDownloadModalExperiment,
    shouldUseRemainingUi: features.useRemainingSearchUI,
  };
}
VideoStockItemCard.contextType = StockDownloadContext;

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateSearchPage,
      setSelectedStockItemOptions,
      collapseMoreLikeThisDrawer,
      expandMoreLikeThisDrawer,
      fetchCollapsedSetResults,
      setPendingPauseAudio,
    },
    dispatch
  ),
});

export default connect(
  mapStateToPropsVideo,
  mapDispatchToProps
)(VideoStockItemCard);
